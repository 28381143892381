<template>
  <el-card
    header="Account Management"
    shadow="never"
  >
    <info-widget
      icon="el-icon-service"
      label="Account Manager"
      :value="company.account_manager ? company.account_manager.name : ''"
    />
    <info-widget
      icon="el-icon-trophy"
      label="Decision Makers"
    >
      <div
        v-for="decisionMaker in company.decision_makers"
        :key="decisionMaker.id"
        class="details-item"
      >
        <link-button
          :to="{ name: 'RooofAccountEdit', params: { id: decisionMaker.id } }"
          type="primary"
        >
          {{ decisionMaker.name }}
        </link-button>
      </div>
    </info-widget>
    <info-widget
      icon="el-icon-help"
      label="Emergency Contacts"
    >
      <div
        v-for="emergencyContact in company.emergency_contacts"
        :key="emergencyContact.id"
        class="details-item"
      >
        <link-button
          :to="{ name: 'RooofAccountEdit', params: { id: emergencyContact.id } }"
          type="primary"
        >
          {{ emergencyContact.name }}
        </link-button>
      </div>
    </info-widget>
    <info-widget
      icon="el-icon-document-copy"
      label="JIRA Mapping URL"
    >
      <link-button
        v-if="company.jira_mapping_url"
        :href="company.jira_mapping_url"
        target="_blank"
        type="primary"
      >
        {{ company.jira_mapping_url }}
      </link-button>
      <span v-else>(none)</span>
    </info-widget>
  </el-card>
</template>

<script>
import { InfoWidget } from '@/components/widgets'
import LinkButton from '@/components/buttons/LinkButton'

export default {
  components: {
    'info-widget': InfoWidget,
    'link-button': LinkButton
  },
  props: {
    company: {
      type: Object,
      required: true
    }
  }
}
</script>
