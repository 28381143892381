<template>
  <div class="status-widget">
    <i class="circle icon" :class="status.toLowerCase()" />
    <div>
      {{ status }}
      <div v-if="date" class="date">
        {{ date }}
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Widget to display a property's status.
 */
const STATUSES = [
  'inactive', 'active', 'trial', 'pre-trial',
  'onboarding', 'offboarding', 'pending', 'invalid', 'auto-created'
]

export default {
  name: 'StatusWidget',
  props: {
    status: {
      type: String,
      required: true,
      validator: value => {
        return STATUSES.includes(value.toLowerCase())
      }
    },
    date: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.status-widget {
  display: flex;
  align-items: baseline;
  text-transform: capitalize;

  .circle {
    height: 10px;
    width: 10px;
    background-color: #bebebe;
    border-radius: 50%;
    display: inline-block;
  }

  .date {
    color: #909399;
    font-size: 0.8em;
  }

  .icon {
    margin: 8px;

    &.inactive {
      background-color: #bebebe;
    }
    &.active {
      background-color: #67C23A;
    }
    &.trial {
      background-color: #409EFF;
    }
    &.pre-trial {
      background-color: #C0C4CC;
    }
    &.onboarding {
      background-color: #909399;
    }
    &.offboarding {
      background-color: #F56C6C;
    }
    &.pending {
      background-color: #E6A23C;
    }
    &.auto-created {
      background-color: #909399;
    }
  }
}
</style>
