<template>
  <el-card
    header="Sales"
    shadow="never"
  >
    <info-widget
      icon="el-icon-s-custom"
      label="Salesperson"
      :value="company.salesperson ? company.salesperson.name : ''"
    />
    <info-widget
      icon="el-icon-office-building"
      label="Managed Properties"
      :value="company.managed_properties ? company.managed_properties.toString() : null"
    />
    <info-widget
      icon="el-icon-d-arrow-right"
      label="Sales Deals"
    >
      <div
        v-for="deal in company.active_campaign_deals"
        :key="deal"
      >
        <link-button
          :href="deal"
          target="_blank"
          type="primary"
        >
          {{ deal }}
        </link-button>
      </div>
    </info-widget>
    <info-widget
      icon="el-icon-s-marketing"
      label="Lead Source"
      :value="getConstantLabelByValue('companyLeadSource', company.lead_source)"
    />
    <info-widget
      label="Organization Type"
      :value="getConstantLabelByValue('organizationType', company.organization_type)"
    >
      <template #icon>
        <category-icon class="category-icon" />
      </template>
    </info-widget>
  </el-card>
</template>

<script>
import CategoryIcon from '@/assets/icons/svg/category.svg'
import { InfoWidget } from '@/components/widgets'
import { getConstantLabelByValue } from '@/utils/constants'
import LinkButton from '@/components/buttons/LinkButton'

export default {
  components: {
    'category-icon': CategoryIcon,
    'info-widget': InfoWidget,
    'link-button': LinkButton
  },
  props: {
    company: {
      type: Object,
      required: true
    }
  },
  created () {
    this.getConstantLabelByValue = getConstantLabelByValue
  }
}
</script>

<style scoped>
.category-icon {
  fill: #626262;
}
</style>
