<template>
  <div v-loading="loading">
    <el-row
      type="flex"
      justify="end"
    >
      <el-button
        icon="el-icon-edit-outline"
        type="primary"
        size="medium"
        @click="$router.push({ name: 'CompanyEdit' })"
      >
        Edit Company Info
      </el-button>
    </el-row>
    <el-row :gutter="20" class="content">
      <el-col
        :xs="24"
        :sm="12"
        :md="8"
        :lg="8"
        :xl="4"
      >
        <company-details-general :company="company" />
      </el-col>

      <el-col
        :xs="24"
        :sm="12"
        :md="8"
        :lg="8"
        :xl="8"
      >
        <el-row :gutter="20">
          <el-col
            :lg="24"
            :xl="12"
          >
            <company-details-account-management :company="company " />
          </el-col>

          <el-col
            :lg="24"
            :xl="12"
          >
            <company-details-sales :company="company" />
          </el-col>
        </el-row>
      </el-col>

      <el-col
        :xs="24"
        :sm="12"
        :md="8"
        :lg="8"
        :xl="4"
      >
        <company-details-billing
          :company="company"
          :zoho="zoho"
          :client-config="clientConfig"
        />
      </el-col>

      <el-col
        :sm="12"
        :md="24"
        :lg="24"
        :xl="8"
      >
        <el-row :gutter="20">
          <el-col
            :md="8"
            :lg="8"
            :xl="12"
          >
            <company-details-posting
              :company="company"
              :client-config="clientConfig"
            />
          </el-col>

          <el-col
            :md="16"
            :lg="16"
            :xl="12"
          >
            <el-row :gutter="20">
              <el-col
                :md="12"
                :lg="12"
                :xl="24"
              >
                <company-details-lead-tracking :company="company" />
              </el-col>

              <el-col
                :md="12"
                :lg="12"
                :xl="24"
              >
                <company-details-full-service
                  :company="company"
                  :client-config="clientConfig"
                />
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import RooofAPI from '@/services/api/rooof'

import CompanyDetailsGeneral from './company-details/CompanyDetailsGeneral'
import CompanyDetailsAccountManagement from './company-details/CompanyDetailsAccountManagement'
import CompanyDetailsSales from './company-details/CompanyDetailsSales'
import CompanyDetailsBilling from './company-details/CompanyDetailsBilling'
import CompanyDetailsPosting from './company-details/CompanyDetailsPosting'
import CompanyDetailsLeadTracking from './company-details/CompanyDetailsLeadTracking'
import CompanyDetailsFullService from './company-details/CompanyDetailsFullService'

export default {
  name: 'CompanyOverviewDetails',
  components: {
    'company-details-general': CompanyDetailsGeneral,
    'company-details-account-management': CompanyDetailsAccountManagement,
    'company-details-sales': CompanyDetailsSales,
    'company-details-billing': CompanyDetailsBilling,
    'company-details-posting': CompanyDetailsPosting,
    'company-details-lead-tracking': CompanyDetailsLeadTracking,
    'company-details-full-service': CompanyDetailsFullService
  },
  props: {
    company: {
      type: Object,
      required: true
    },
    zoho: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      clientConfig: {}
    }
  },
  created () {
    this.fetchClientConfiguration()
  },
  methods: {
    async fetchClientConfiguration () {
      try {
        this.loading = true
        const response = await RooofAPI.config.retrieve(this.$route.params.cid)
        this.clientConfig = response
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pre-formatted {
  white-space: pre-wrap;
}
.content {
  margin-top: 20px;
}
::v-deep .el-card {
  margin-bottom: 20px;

  .el-card__header {
    color: #34495e;
    font-weight: 700;
    font-size: 1.125rem;
  }
}
</style>
