<template>
  <el-card
    v-loading="loading"
    header="Full Service"
    shadow="never"
  >
    <info-widget
      icon="el-icon-user"
      label="Full Service Poster"
      :value="posterName"
    />
    <info-widget
      icon="el-icon-s-check"
      label="Daily Posting Target"
      :value="company.daily_posting_target.toString()"
    />
    <info-widget
      label="Full Service in Chrome"
      :value="parseClientConfigFlag(clientConfig.enableAgencyDummyModeInChrome)"
    >
      <template #icon>
        <google-chrome-icon class="google-icon" />
      </template>
    </info-widget>
    <info-widget
      icon="el-icon-key"
      label="Access to the Scheduled Posting Tab"
      :value="parseClientConfigFlag(clientConfig.allowScheduledPostsTabAccessOverride)"
    />
  </el-card>
</template>

<script>
import GoogleChromeIcon from '@/assets/icons/svg/google_chrome.svg'
import RooofAccountAPI from '@/services/api/accounts'
import { InfoWidget } from '@/components/widgets'

export default {
  components: {
    'info-widget': InfoWidget,
    'google-chrome-icon': GoogleChromeIcon
  },
  props: {
    company: {
      type: Object,
      required: true
    },
    clientConfig: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      posterName: ''
    }
  },
  created () {
    this.getPosterName()
  },
  methods: {
    /**
     * Retrieves the name of the Agency Poster assigned to the company.
     */
    async getPosterName () {
      try {
        this.loading = true
        const response = await RooofAccountAPI.users.list({ id: this.company.agency_poster })
        this.posterName = response.length ? response[0].name : 'Unknown'
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loading = false
      }
    },
    /**
     * Returns parsed boolean to Enabled or Disabled as string
     * @param {Boolean} value
     */
    parseClientConfigFlag (value) {
      if (value === true) {
        return 'Enabled'
      } else if (value === false) {
        return 'Disabled'
      }
      return value
    }
  }
}
</script>

<style scoped>
.google-icon {
  width: 20px;
  fill: #626262;
  padding-left: 2px;
}
</style>
