<template>
  <el-card
    header="Lead Tracking"
    shadow="never"
  >
    <info-widget
      icon="el-icon-link"
      label="Short URL Domain"
    >
      <link-button
        v-if="company.short_url_domain"
        :href="`https://${company.short_url_domain}`"
        target="_blank"
        type="primary"
      >
        {{ company.short_url_domain }}
      </link-button>
      <span v-else>(none)</span>
    </info-widget>
    <info-widget
      icon="el-icon-set-up"
      label="Short URL Parameters"
      :value="company.short_url_params"
    />
    <info-widget
      icon="el-icon-s-data"
      label="Emailhandler Lead Source"
      :value="company.emailhandler_lead_source"
    />
  </el-card>
</template>

<script>
import { InfoWidget } from '@/components/widgets'
import LinkButton from '@/components/buttons/LinkButton'

export default {
  components: {
    'info-widget': InfoWidget,
    'link-button': LinkButton
  },
  props: {
    company: {
      type: Object,
      required: true
    }
  }
}
</script>
