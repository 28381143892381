<template>
  <el-card
    v-loading="loading"
    header="General"
    shadow="never"
  >
    <info-widget
      icon="el-icon-suitcase"
      label="Company"
      :value="company.human_name"
    />
    <info-widget
      icon="el-icon-question"
      label="Status"
    >
      <status-widget :status="company.status" />
    </info-widget>
    <info-widget
      icon="el-icon-date"
      label="Start Date"
      :value="company.start_date"
    />
    <info-widget
      icon="el-icon-date"
      label="End Date"
      :value="company.end_date"
    />
    <info-widget
      icon="el-icon-circle-close"
      label="Reason for Cancel"
      :value="getConstantLabelByValue('reasonForCancel', company.reason_for_cancel)"
    />
    <info-widget
      icon="el-icon-lock"
      label="Activation Code"
      :value="activationCode"
    />
    <info-widget
      icon="el-icon-menu"
      label="Category"
      :value="getConstantLabelByValue('companyCategory', company.category)"
    />
    <info-widget
      icon="el-icon-message"
      label="Email Domains"
      :collapsible="company.email_domains.length > 5"
    >
      <div v-for="domain in company.email_domains" :key="domain">
        {{ domain }}
      </div>
    </info-widget>
  </el-card>
</template>

<script>
import CraigslistAPI from '@/services/api/craigslist'
import { getConstantLabelByValue } from '@/utils/constants'
import { InfoWidget, StatusWidget } from '@/components/widgets'

export default {
  components: {
    'info-widget': InfoWidget,
    'status-widget': StatusWidget
  },
  props: {
    company: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      activationCode: ''
    }
  },
  created () {
    this.getConstantLabelByValue = getConstantLabelByValue
    this.getActivationCode()
  },
  methods: {
    /**
     * Retrieves the top level reporting group's activation code from the API.
     */
    async getActivationCode () {
      try {
        this.loading = true
        const params = { company_id: this.$route.params.cid }
        const response = await CraigslistAPI.groups.list(params)
        this.activationCode = response.length ? response[0].activation_code : 'Unknown'
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
