<template>
  <div class="info-widget">
    <el-row>
      <el-col :span="4" class="icon">
        <i :class="icon" />
        <slot name="icon" />
      </el-col>
      <el-col v-if="collapsible" :span="20">
        <el-collapse v-model="expanded">
          <el-collapse-item :title="label">
            <slot>
              {{ value || '(none)' }}
            </slot>
          </el-collapse-item>
          <template v-if="!expanded.length">
            ...
          </template>
        </el-collapse>
      </el-col>
      <el-col v-else :span="20">
        <el-row>
          <el-col class="label">
            {{ label }}
          </el-col>
        </el-row>
        <el-row>
          <el-col class="value">
            <slot>
              {{ value || '(none)' }}
            </slot>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'InfoWidget',
  props: {
    icon: {
      type: String,
      default: undefined
    },
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ''
    },
    collapsible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      expanded: []
    }
  }
}
</script>

<style lang="scss" scoped>
.info-widget {
  color: #626262;
  padding-top: 8px;
  padding-bottom: 8px;

  .icon {
    font-size: 24px;
    width: 40px;
  }
  .label {
    padding-top: 4px;
    padding-bottom: 4px;
    font-weight: bold;
  }
  .value {
    font-weight: 500;
    word-break: break-word;
  }
  ::v-deep .el-collapse {
    border: none;
    .el-collapse-item__header {
      color: #626262;
      font-size: 16px;
      font-weight: bold;
      border-bottom: none;
      height: auto;
      line-height: unset;
      .el-collapse-item__arrow {
        margin: 0 8px;
      }
    }
    .el-collapse-item__wrap {
      border: none;
      .el-collapse-item__content {
        padding: 8px 0;
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
        color: #626262;
      }
    }
  }
}
</style>
