<template>
  <div>
    <el-card
      v-loading="loading"
      header="Billing"
      shadow="never"
    >
      <info-widget
        v-if="canViewAgreements"
        icon="el-icon-document-checked"
        label="Agreement"
      >
        <el-button
          v-for="agreement in agreements"
          :key="agreement.id"
          type="text"
          class="agreement-link"
          @click="previewAgreement(agreement)"
        >
          {{ agreement.name }}
        </el-button>
      </info-widget>
      <info-widget
        v-if="canViewAgreements"
        icon="el-icon-document"
        label="Agreement Type"
        :value="company.agreement_type"
      />
      <info-widget
        icon="el-icon-money"
        label="Billing Frequency"
        :value="getConstantLabelByValue('billingFrequency', company.billing_frequency)"
      />
      <info-widget
        icon="el-icon-bank-card"
        label="Billing Method"
        :value="getConstantLabelByValue('billingMethods', company.default_billing_method)"
      />
      <info-widget
        icon="el-icon-price-tag"
        label="Default Rate"
        :value="company.default_rate"
      />
      <info-widget
        icon="el-icon-coin"
        label="Currency"
        :value="company.currency_code"
      />
      <info-widget
        icon="el-icon-notebook-1"
        label="Zoho Recurring Invoice ID"
        :value="zoho.recurringInvoiceId"
      />
      <info-widget
        icon="el-icon-notebook-2"
        label="Zoho Customer ID"
        :value="zoho.customerId"
      />
      <info-widget
        icon="el-icon-tickets"
        label="Billing Notes"
        :value="company.billing_notes"
        :collapsible="company.billing_notes.length > 200"
      />
      <info-widget
        icon="el-icon-bell"
        label="Unpaid Invoice Notifications"
        :value="parseClientConfigFlag(clientConfig.enableFeatureDegradation)"
      />
    </el-card>
    <el-dialog :visible.sync="showAgreementDialog" title="Agreement Preview">
      <iframe
        class="agreement-preview"
        :src="agreementPreview.url"
        :type="agreementPreview.mimeType"
      />
    </el-dialog>
  </div>
</template>

<script>
import RooofAPI from '@/services/api/rooof'
import { InfoWidget } from '@/components/widgets'
import { getConstantLabelByValue } from '@/utils/constants'

export default {
  components: {
    'info-widget': InfoWidget
  },
  props: {
    company: {
      type: Object,
      required: true
    },
    zoho: {
      type: Object,
      required: true
    },
    clientConfig: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      canViewAgreements: false,
      agreements: [],
      agreementPreview: {},
      showAgreementDialog: false
    }
  },
  created () {
    this.getConstantLabelByValue = getConstantLabelByValue
    this.loadAgreements()
  },
  methods: {
    /**
     * Determines the user's level of access to agreements and fetches
     * the list of agreements from the API if appropriate.
     */
    async loadAgreements () {
      try {
        this.loading = true
        const permissions = await RooofAPI.companies.permissionsList(this.company.id)
        this.canViewAgreements = permissions.includes('view_company_agreements') || this.$store.getters['auth/isStaff']
        if (this.canViewAgreements) {
          this.agreements = await RooofAPI.agreements.list(this.company.id, 'companies')
        }
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loading = false
      }
    },
    /**
     * Opens the agreement preview dialog with the corresponding agreement in the iframe.
     */
    previewAgreement (agreement) {
      this.agreementPreview = agreement
      this.showAgreementDialog = true
    },
    /**
     * Returns parsed boolean to Enabled or Disabled as string
     * @param {Boolean} value
     */
    parseClientConfigFlag (value) {
      if (value === true) {
        return 'Enabled'
      } else if (value === false) {
        return 'Disabled'
      }
      return value
    }
  }
}
</script>

<style scoped>
.agreement-link {
  padding: 0;
  margin: 0;
}
.agreement-preview {
  height: 60vh;
  width: 100%;
  border: none;
}
</style>
